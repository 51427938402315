import React from 'react'
import { Link, graphql, useStaticQuery, navigate } from 'gatsby'
import { LinkedinOutlined, MenuOutlined, TwitterOutlined, YoutubeOutlined } from '@ant-design/icons';
import { Col, Button, Dropdown, Menu, Row } from 'antd';
import { colorPrimary } from '../utils/colors'
import { getAuthState } from '../utils/auth'
import Logo from '../images/logo.png'

import WebsiteFooter from '../components/WebsiteFooter'

import WebsiteHeader from '../components/WebsiteHeader'

const WebContainer = ({ children }) => {

  const authState = getAuthState()

  return (
    <div className="outerContainer">
      <div className="flex flex-col justify-center">
        <WebsiteHeader authState={authState}></WebsiteHeader>
        <div className="webContainerContent">{children}</div>
      </div>

      <WebsiteFooter></WebsiteFooter>
    </div>
  );
}

const dropdownMenu = (
  <Menu>
    <Menu.Item key="Sync">
      <Link to="/sync">Sync</Link>
    </Menu.Item>

    <Menu.Item key="Schedule">
      <Link to="/schedule">Schedule</Link>
    </Menu.Item>

    <Menu.Item key="Pricing">
      <Link to="/pricing">Pricing</Link>
    </Menu.Item>

    <Menu.Item key="Support">
      <a href="https://help.calendarbridge.com">Support</a>
    </Menu.Item>

    <Menu.Item key="LogIn">
      <Link to="/login">Log In</Link>
    </Menu.Item>

    <Menu.Item key="SignUp">
      <Link to="/signup" style={{ color: colorPrimary }}>
        Sign Up
      </Link>
    </Menu.Item>
  </Menu>
)

const dropdownMenuSignedIn = (
  <Menu>
    <Menu.Item key="LogIn">
      <Link to="/dashboard">Dashboard</Link>
    </Menu.Item>

    <Menu.Item key="Support">
      <a href="https://help.calendarbridge.com">Support</a>
    </Menu.Item>

    <Menu.Item key="Pricing">
      <Link to="/pricing">Pricing</Link>
    </Menu.Item>
  </Menu>
)

export default WebContainer

import React from 'react'
const Highlight = ({ label, color = 'white', w = 32 }) => {
    return (
      
        <span className={`highlight-${color}`}>{label}</span>
       
 
    )
  }


export default Highlight
import React, {useEffect} from 'react'
import WebContainer from '../components/webcontainer'
import SocialProof from '../components/socialproof'
import PrivacyFirst from '../components/privacyfirst'
import ForEnterprise from '../components/forenterprise'
import { Row, Col, Icon, Popover, Button, Carousel, Card } from 'antd'
import shareImage from '../images/sync_share_image.png'
import SEO from '../components/SEO'
import howItWorksImg1 from '../images/link-account.png'
import howItWorksImg2 from '../images/select-calendars.png'
import howItWorksImg3 from '../images/sync-calendars.png'
import WebsiteFooter from '../components/WebsiteFooter'
import { getAuthState } from '../utils/auth'
import WebsiteHeader from '../components/WebsiteHeader'
import CtaForm1 from '../components/ctaform1'
import Step3 from '../images/step_3_schedule.png'
import Step2 from '../images/step_2_schedule.png'
import Step1 from '../images/step_1_schedule.png'

import Scheduler from '../images/svg/scheduler.svg'
import HeroImage from '../images/hero-tractor-beam.png'
import Highlight from '../components/Highlight'

import { Link, navigate } from 'gatsby'
import { faDove } from '@fortawesome/free-solid-svg-icons'

import Brands from '../images/brands.png'
import Shield from '../images/svg/bg-shield.svg'

const Index = ({ data }) => {
  const authState = getAuthState()
  useEffect(() => {
    if(window.location.host.includes("app")) {
      window.location.href = ('/dashboard'); // Redirect to the desired route
     }
}, []);
  const SectionHero = ({}) => {
    return (
      <div
        className="flex flex-col lg:flex-row items-center pl-3 pr-3 lg:pr-0  lg:pl-0 justify-center lg:justify-start h-full  w-full"
        style={{ maxWidth: 800 }}
      >
        <div className="lg:w-2/3 text-brand_dark_blue  flex order-2 lg:order-1 flex-col ">
          <div className="flex text-3xl font-lato mb-4 justify-center lg:justify-start">
            <div>Scheduling for people with <br /><Highlight label="Multiple Calendars." color="white" w={24}></Highlight></div>
            <div className="w-1 lg:w-2"></div>
            
          </div>
          <div className="font-lato text-lg text-brand_dark_blue flex-1 lg:w-2/3 justify-center lg:justify-start">
            Schedule Meetings with less back-and-forth while ensuring accurate availability across all your calendars.
          </div>
          <div className="flex justify-center lg:justify-start">
              <Link className="font-roboto text-md text-center rounded-md pl-4 pr-4 pt-2 pb-2 mt-6 mb-5 hover:bg-burnt bg-dark_burnt text-white w-2/3"
              to = '/signup?referral=schedule'
              >
                Try CalendarBridge For Free
              </Link>
            </div>
            <div className="text-text_website font-roboto text-md text-center lg:text-left">
              Already a user? <a href="/dashboard">Access your dashboard</a>
            </div>
        </div>
        <div className="flex w-full lg:w-1/3 justify-center order-1 lg:order-2">
        <img src={HeroImage}></img>
        </div>
      </div>
    )
  }

  const Explainer = ({}) => {
    return (
      <div className="flex pl-3 pr-3 lg:pr-0 lg:pl-0 pt-20 text-lg pb-20 flex-col bg-white items-center text-brand_dark_blue justify-center self-center w-full">
        <div className="font-lato text-3xl lg:text-5xl mb-8">
          Why CalendarBridge?
        </div>
        <div className="grid lg:divide-x  grid-cols-1 md:grid-cols-1 lg:grid-cols-3 gap-8 lg:space-y-0  mt-10 ">
          <div>
            <div className="lg:w-80 mb-8 font-bold text-center">
              Availability based on multiple calendars
            </div>
            <div className="lg:w-80">
              CalendarBridge checks all your calendars and is timezone aware so your availability is always up-to-date and easy to view.{' '}
            </div>
          </div>
          <div>
            <div className="lg:w-80 mb-8 font-bold text-center">
              Sync booked meetings across calendars
            </div>
            <div className="lg:w-80 lg:pl-4">
            Time booked on one calendar is blocked off on your other calendars. Co-workers see your accurate availability directly in Outlook and Google Calendar.
            </div>
          </div>
          <div>
            <div className="lg:w-80 mb-8 font-bold text-center">
              Different scheduling pages for different calendars
            </div>
            <div className="lg:w-80 lg:pl-8">
              Each of your 5 scheduling pages can have different branding and book to a different calendar, all while ensuring consistent availability across all your calendars.
            </div>
          </div>
        </div>
      </div>
    )
  }

  const BigQuote = ({}) => {
    return (
      <div className="flex pl-3 pr-3 lg:pr-0 lg:pl-0 pt-10 lg:text-2xl text-xl pb-10 flex-col bg-brand_dark_blue items-center text-white justify-center self-center w-full">
            <div className="w-3/4 lg:w-2/3 font-bold text-center">
            "For consultants, fractional resources, educators, and others with multiple calendars, CalendarBridge is the only simple, affordable scheduling solution."
        </div>
      </div>
    )
  }

  const Step = ({ number, text, image, opposite = false }) => {
    return (
      <div className="flex flex-col lg:flex-row text-lg justify-start w-full lg:w-1/2 self-center mb-10">
        <div className={`p-3 lg:p-20 flex flex-col ${opposite ? `order-2` : 'order-1'}`}>
          <div className="mb-4 font-bold text-brand_dark_blue">
            Step {number}
          </div>
          <div className="lg:w-64 text-brand_dark_blue">{text}</div>
        </div>
        <div className={`flex flex-1 justify-center lg:justify-end ${opposite ? `order-1` : 'order-2'}`}>{image}</div>
      </div>
    )
  }
  const Steps = () => {
    return (
      <div className="bg-creme grid grid-cols-1 justify-center pt-20 pb-20  self-center w-full">
        <div className="flex font-lato text-lg lg:text-5xl text-brand_dark_blue w-full justify-center lg:mb-16">
          <div className="flex flex-row gap-3"><div>How</div> <Highlight label="Scheduler" color="white"></Highlight> <div>works</div></div>
        </div>
        <div style={{}} className="flex flex-col justify-center">
          <Step
            number={1}
            image={<img width="300" src={Step1}></img>}
            text={
              <div>
                Select the calendars you would like to use for scheduling.{' '}
              </div>
            }
          ></Step>
          <Step
            number={2}
            opposite={false}
            image={<img width="300" src={Step2}></img>}
            text={
              <div>
                Choose your availability and cutomize your scheduling options.
              </div>
            }
          ></Step>
          <Step
            number={3}
            image={<img width="300" src={Step3}></img>}
            text={
              <div>
                Add your event details so attendees have access to the
                information they need for your meeting.{' '}
                <div
                  onClick={(e) => navigate('/signup')}
                  className="cursor-pointer w-56 bg-burnt mt-8 pl-4 pr-4 pt-2 pb-2 text-center text-white rounded-sm shadow-sm"
                >
                  Send a Scheduling Link
                </div>
              </div>
            }
          ></Step>
          <div className="flex justify-center">
            <div className="cursor-pointer bg-brand_dark_blue pl-4 pr-4 pt-2 pb-2 text-white rounded-sm shadow-sm">
              Get Scheduler Support
            </div>
          </div>
        </div>
      </div>
    )
  }

  const CallToAction = () => {
    return (
      <div className="bg-white grid grid-cols-1 justify-center pt-20 pb-20  self-center w-full">
        <div className="flex font-lato text-lg lg:text-5xl text-brand_dark_blue w-full justify-center mb-16">
        <div className="flex flex-row gap-3"><div>Ready to</div> <Highlight label="simplify" color="creme"></Highlight> <div>scheduling?</div></div>
        </div>
        <div className="flex justify-center"><Scheduler width="600"></Scheduler></div>
        <div className="flex justify-center">
          <CtaForm1 referral="schedule"></CtaForm1>
        </div>
      </div>
    )
  }

  const Section = ({ title, content }) => {
    return (
      <div  className="flex flex-col lg:flex-row  justify-center w-full mb-24 "
      style={{ maxWidth: 1200, zIndex: 1 }}>
        <div className="flex justify-center lg:justify-start lg:w-1/3 w-full ">
          <div className="font-lato text-brand_dark_blue text-5xl flex flex-row  mt-4 lg:mb-0 mb-12">
            {title}
          </div>
        </div>
        <div className="flex lg:w-2/3 w-full justify-center  lg:justify-start">
          {content}
        </div>
      </div>
    )
  }

  const TrustedBy = () => {
  return (
    <div className=" bg-white pt-24 flex w-full flex-col items-center justify-center justify-items-center">
    <Section
      title={
        <div className=" bg-white">
          <div className="font-lato text-brand_dark_blue text-lg lg:text-5xl flex flex-row">
            <div>
              Trusted by{' '}
              <Highlight label="global" color="creme" w={32}></Highlight>{' '}
              organizations
            </div>
            {/* <div className="relative">
          <span className="absolute">new</span>
          <div className="mt-8 w-24 h-4 bg-creme"></div>
        </div> */}{' '}
          </div>
        </div>
      }
      content={
        <div className="pl-3 pr-2  lg:pr-0">
          <img width="600" src={Brands}></img>
        </div>
      }
    ></Section>

    <Section
      title={
        <div className="bg-white">
          <div className="font-lato text-brand_dark_blue text-lg lg:text-5xl flex flex-col">
            <div>
              Made for{' '}
              <Highlight label="you" color="creme" w={20}></Highlight>{' '}
            </div>
            {/* <div className="relative">
          <span className="absolute">new</span>
          <div className="mt-8 w-24 h-4 bg-creme"></div>
        </div> */}{' '}
          </div>
        </div>
      }
      content={
        <div className="lg:ml-1 grid lg:grid-cols-3 grid-cols-1 lg:space-x-8 text-center lg:text-left justify-center items-center">
          <div className="flex flex-col justify-between h-full">
            <div className="font-lato text-lg text-brand_dark_blue mb-2">
              There’s nothing to install.
            </div>
            <div className="font-roboto text-brand_dark_blue text-center lg:text-left p-12 lg:p-0" style={{ height: 168 }}>
              Simply customize your settings and our cloud-based
              technology will take care of the rest.{' '}
            </div>
          </div>
          <div className="flex flex-col justify-between h-full">
            <div className="font-lato text-lg text-brand_dark_blue mb-2">
              We provide expert security.
            </div>
            <div className="font-roboto text-brand_dark_blue p-12 lg:p-0" style={{ height: 168 }}>
              Our background in cybersecurity means we understand
              enterprise-grade protection and implement it every day.
            </div>
          </div>
          <div className="flex flex-col justify-between h-full">
            <div className=" font-lato text-lg text-brand_dark_blue mb-2 ">
              Real-time availability makes scheduling easy.{' '}
            </div>
            <div className="font-roboto text-brand_dark_blue p-12 lg:p-0">
              Because CalendarBridge syncs all of your calendars across
              all domains and platforms, your availability is always
              accurate and up-to-date.
            </div>
          </div>
        </div>
      }
    ></Section>
  </div>
  )
}

const PrivacyAndEnterprise = () => {
  return (
    <div className="flex flex-col bg-brand_blue w-full items-center justify-center justify-items-center pt-8 pl-2 lg:pl-8">
          <Section
            title={
              <div className="mt-12 text-white font-lato  text-lg lg:text-5xl flex lg:flex-col flex-row">
                <div className=" flex flex-row">
                  <div>Your </div>
                  <div className="w-2"></div>
                  <Highlight
                    label="privacy"
                    color="brand_dark_blue"
                    w={32}
                  ></Highlight>{' '}
                </div>
                <div className="ml-2 lg:ml-0">comes first</div>
              </div>
            }
            content={
              <div className="flex flex-col items-center p-8 lg:p-0 lg:mt-20 lg:w-96">
                <div>
                  <div>
                    <div className="text-white mb-4">
                      Our goal is simplicity in calendaring – not hoarding your
                      personal information. That’s why we never analyze any of
                      your calendar data.{' '}
                    </div>

                    <div className="text-white mb-2">
                      CalendarBridge never views or stores your Google or
                      Microsoft login credentials, does not stores details of
                      your calendar events, and has no access to your emails.
                    </div>
                    <Link className="text-white underline bold hover:text-brand_dark_blue hover:underline">
                      More about our privacy and security
                    </Link>
                  </div>
                </div>
              </div>
							
            }
          ></Section>

          <Section
            title={
              <div className="text-lg lg:text-5xl font-lato lg:mt-12 text-white ">
                <Highlight
                  label="Enterprise ready"
                  color="brand_dark_blue"
                  w={48}
                ></Highlight>{' '}
                calendar solution
              </div>
            }
            content={
              <div className="flex flex-col lg:mt-20">
                <div className="lg:w-96 p-8 lg:p-0">
                  <div>
                    <div className="text-white mb-4">
                      In addition to our best-in-class security and privacy
                      practices, we offer IT adminstrators all the tools they
                      need to manage CalendarBridge for teams of all sizes.
                      Manage billing, licenses, and custom privacy controls all
                      from our intuitive admin portal.
                    </div>
                    <Link className="text-white underline bold hover:text-brand_dark_blue hover:underline">
                      Learn more about our admin tools
                    </Link>
                  </div>
                </div>
              </div>
            }
          ></Section>

          <div style={{ position: 'absolute', right: 0 }}>
                <div className="flex-grow"></div>
                <Shield></Shield>
              </div>
        </div>
  )
}

  return (
    <>
      <SEO
        title="Multi-Calendar Scheduling | CalendarBridge"
        description="CalendarBridge digital calendar sync checks all your calendars so your availability is always up-to-date and easy to book with multiple parties."
        image={shareImage}
      />
      <div className="flex flex-col justify-center">
        <div className="bg-creme grid grid-cols-1">
          <WebsiteHeader theme="light" authState={authState}></WebsiteHeader>
          <div className="flex justify-center mt-10 mb-10">
            <SectionHero></SectionHero>
          </div>
        </div>
        <Explainer></Explainer>
        <BigQuote></BigQuote>
        <Steps></Steps>
        <TrustedBy></TrustedBy>
        <PrivacyAndEnterprise></PrivacyAndEnterprise>
        <CallToAction></CallToAction>
      </div>
      <WebsiteFooter></WebsiteFooter>
    </>
  )
}

export default Index

const styles = {
  card: {
    backgroundColor: 'white',
    boxShadow:
      '0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)',
    margin: '8px 8px',
    padding: '30px 20px 100px 20px',
    borderRadius: '5px',
  },
}

const IconCard = ({ IconCardIcon, IconCardTitle, children }) => {
  return (
    <Col
      xs={24}
      md={6}
      style={{
        display: 'flex',
        justifyContent: 'center',
        maxWidth: '360px',
      }}
    >
      <Row>
        <Col span={24} style={{ display: 'flex', justifyContent: 'center' }}>
          <Icon
            type={IconCardIcon}
            style={{
              fontSize: '5em',
              color: '#09467D',
              marginBottom: '20px',
            }}
          />
        </Col>
        <Col span={24}>
          <h2
            style={{
              color: '#09467D',
              display: 'flex',

              marginBottom: '0px',
            }}
          >
            {IconCardTitle}
          </h2>
          <br />
        </Col>
        <Col span={24}>
          <h4 style={{ color: '#09467D', fontSize: '1.3em' }}>{children}</h4>
        </Col>
      </Row>
    </Col>
  )
}

const ImgCard = ({ imgCardImage, imgCardTitle, children }) => {
  return (
    <Col
      xs={24}
      md={6}
      style={{
        display: 'flex',
        justifyContent: 'center',
        align: 'center',
      }}
    >
      <div
        style={{
          maxWidth: '300px',
        }}
      >
        <img style={{ margin: '0px 0px 10px 0px' }} src={imgCardImage} />
        <h2
          style={{
            color: '#09467D',
            lineHeight: '1.1em',
            marginBottom: '0.4em',
            fontSize: '2em',
          }}
        >
          {imgCardTitle}
        </h2>
        <h4
          style={{
            color: '#09467D',
          }}
        >
          {children}
        </h4>
      </div>
    </Col>
  )
}
